const ServiceWorker = (() => {
  function init() {
    const jobs = Array.from(document.getElementsByClassName('jobs__has_id'));
    const version = window.serviceWorkerVersion;
    const dependencyIds = [];

    jobs.forEach((jobElement) => {
      dependencyIds.push(jobElement.getAttribute('data-id'));
    });

    const serialisedDependencyArray = JSON.stringify(dependencyIds);

    if ('serviceWorker' in navigator) {
      console.log('CLIENT: service worker registration in progress.');
      navigator.serviceWorker.register('/service-worker.js?swVersion=' + version + '&dependencyIds=' + encodeURIComponent(serialisedDependencyArray) + '&apiRoot=' + window.location.origin + '/wp-json/service-worker/v1/dependencies')
        .then(function() {
          console.log('CLIENT: service worker registration complete.');
        }, function() {
          console.log('CLIENT: service worker registration failure.');
        });
    }
  }

  return {
    init
  };

})();

export default ServiceWorker;
